export default {
    backendOptions: [
      { value: "http://127.0.0.1:25500/sub?" },
      { value: "http://192.168.1.60:25500/sub?" },
      { value: "http://58.49.222.84:25500/sub?" },
      { value: "http://ladder.yuwuxing.com:25500/sub?" },
    ],
    remoteConfig: [
      {
        label: "Custom",
        options: [
          {
            label: "Custom-Mini",
            value:
              "http://ladder.yuwuxing.com/rules/Custom_Mini.ini"
          },
          {
            label: "Custom-Full",
            value:
              "http://ladder.yuwuxing.com/rules/Custom_Full.ini"
          },
          {
            label: "Custom-ysq",
            value:
              "http://ladder.yuwuxing.com/rules/Custom_ysq.ini"
          }
        ]
      },
        {
          label: "universal",
          options: [
            {
              label: "No-Urltest",
              value:
                "https://cdn.jsdelivr.net/gh/SleepyHeeead/subconverter-config@master/remote-config/universal/no-urltest.ini"
            },
            {
              label: "Urltest",
              value:
                "https://cdn.jsdelivr.net/gh/SleepyHeeead/subconverter-config@master/remote-config/universal/urltest.ini"
            }
          ]
        },
        {
          label: "customized",
          options: [
            {
              label: "Maying",
              value:
                "https://cdn.jsdelivr.net/gh/SleepyHeeead/subconverter-config@master/remote-config/customized/maying.ini"
            },
            {
              label: "Ytoo",
              value:
                "https://cdn.jsdelivr.net/gh/SleepyHeeead/subconverter-config@master/remote-config/customized/ytoo.ini"
            },
            {
              label: "FlowerCloud",
              value:
                "https://cdn.jsdelivr.net/gh/SleepyHeeead/subconverter-config@master/remote-config/customized/flowercloud.ini"
            },
            {
              label: "Nexitally",
              value:
                "https://cdn.jsdelivr.net/gh/SleepyHeeead/subconverter-config@master/remote-config/customized/nexitally.ini"
            },
            {
              label: "SoCloud",
              value:
                "https://cdn.jsdelivr.net/gh/SleepyHeeead/subconverter-config@master/remote-config/customized/socloud.ini"
            },
            {
              label: "ARK",
              value:
                "https://cdn.jsdelivr.net/gh/SleepyHeeead/subconverter-config@master/remote-config/customized/ark.ini"
            },
            {
              label: "ssrCloud",
              value:
                "https://cdn.jsdelivr.net/gh/SleepyHeeead/subconverter-config@master/remote-config/customized/ssrcloud.ini"
            }
          ]
        },
        {
          label: "Special",
          options: [
            {
              label: "NeteaseUnblock(仅规则，No-Urltest)",
              value:
                "https://cdn.jsdelivr.net/gh/SleepyHeeead/subconverter-config@master/remote-config/special/netease.ini"
            },
            {
              label: "Basic(仅GEOIP CN + Final)",
              value:
                "https://cdn.jsdelivr.net/gh/SleepyHeeead/subconverter-config@master/remote-config/special/basic.ini"
            }
          ]
        }
      ]
}